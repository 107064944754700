// 资格解锁订单
<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="getList">
    </table-search>
    <div class="table">
      <el-table
        :data="tableData"
        border
        @filter-change="filterChange"
        style="width: 100%">
        <el-table-column
          prop="orderId"
          label="订单ID">
        </el-table-column>
        <el-table-column
          prop="accountId"
          label="用户ID">
        </el-table-column>
        <el-table-column
          prop="mainName"
          label="主体姓名">
        </el-table-column>
        <el-table-column
          prop="mainType"
          label="主体类型">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.orange}" v-show="scope.row.mainType==1">个人</span>
            <span :style="{'color':COLOR.red}" v-show="scope.row.mainType==2">企业</span>
            <span :style="{'color':COLOR.green}" v-show="scope.row.mainType==3">律师</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderNo"
          label="商户订单号">
        </el-table-column>
        <el-table-column
          prop="payOrderNo"
          label="支付订单号">
        </el-table-column>
        <el-table-column
          prop="payChannel"
          label="支付渠道">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.green}" v-show="scope.row.payChannel==1">微信</span>
            <span :style="{'color':COLOR.blue}" v-show="scope.row.payChannel==2">支付宝</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="payAmount"
          label="支付金额">
        </el-table-column>

        <el-table-column
          prop="payStatus"
          :filters="payFilter"
          column-key="payStatus"
          label="状态">
          <template slot-scope="scope">
            <span :style="{'color':COLOR[payStatusOptions['color_'+scope.row.payStatus]]}" >{{payStatusOptions[scope.row.payStatus]}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="id"
          label="时间">
          <template slot-scope="scope">
            <div>下单时间：{{scope.row.payTime}}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          prop="val"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="checkHandle(scope.row)">支付详情</el-button>
            <el-button type="text" @click="refundHandle(scope.row)" v-if="scope.row.payStatus == 2">订单退款</el-button>
            <el-button type="text" @click="recordHandle(scope.row)">操作日志</el-button>
              
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          :current-page="form.page"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <pay-detail ref="payDetail" :info="info"></pay-detail>
    <el-dialog title="操作日志" :visible.sync="recordVisible">
      <el-table :data="gridData" border>
        <el-table-column prop="operatorName" label="姓名"></el-table-column>
        <el-table-column prop="refundTime" label="操作时间"></el-table-column>
        <el-table-column prop="comments" label="备注"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import PayDetail from './coms/pay-detail.vue'
import {releaseOrderList,orderDetail,refund} from '../../api/order'
import {mapState} from 'vuex'
export default {
  name:'signup',
  data () {
    return {
      recordVisible:false,
      gridData:[],
      total:0,
      info:{},
      form:{
        page:1,
        perPage:10,
        status:''
      },
      conditions:[
        {value:'orderNo',label:'商户订单号'},
        {value:'payOrderNo',label:'支付订单号'},
        {value:'mainName',label:'主体姓名'},
        {value:'orderId',label:'订单ID'},
        {value:'accountId',label:'用户ID'},
      ],
      tableData:[
        
      ],
      multipleSelection:[],
        statusFilter:[
          {text: '正常', value: '1'}, 
          {text: '禁言', value: '2'}, 
          {text: '禁止登陆', value: '3'}
        ],
        applyFilter:[
          {text: '诉讼业务', value: '1'}, 
          {text: '婚姻家庭', value: '2'}, 
          {text: '合同纠纷', value: '3'},
          {text: '非诉讼业务', value: '4'},
          {text: '法律顾问', value: '5'},
          {text: '劳动纠纷', value: '6'},
          {text: '金融证券', value: '7'},
          {text: '公司经营', value: '8'},
          {text: '法律文书', value: '9'},
        ],
        typeFilter:[
            {text: '等待选标中', value: '1'},
            {text: '已中标', value: '2'}, 
            {text: '未中标', value: '3'}
        ],
        subjectFilter:[
            {text: '个人', value: 1},
            {text: '企业', value: 2}, 
            {text: '律师', value: 3}, 
        ]
    };
  },
  components: {
    TableSearch,
    PayDetail
  },
  computed: {
    ...mapState({
      payStatusOptions: state => state.dictionary.payStatus
    }),
    payFilter:function(){
      let res=[]
      for(let key in this.payStatusOptions){
        if(key.indexOf('color') < 0){
          res.push({
            text:this.payStatusOptions[key],
            value:key
          })
        }
        
      }
      return res;
    }
  },
  methods: {
    getList(){
      releaseOrderList(this.form).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.tableData = data.content.list;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    searchRes(para){
      this.form = Object.assign({
        page:this.form.page,
        perPage:this.form.perPage,
        status:this.form.status
        },para);
      this.getList();
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page = 1;
      this.getList();
    },
    addHandle(){},
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val)
    },
    checkHandle(row){
      orderDetail({orderId:row.orderId}).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.info = data.content;
        }else{
          console.log(data.desc)
        }
      })
      this.$refs.payDetail.dialogVisible = true;
    },
    filterChange(filters){
      if(filters.payStatus){
        this.form.status = filters.payStatus.join();
      }
      this.form.page = 1;
      this.getList();
    },
    refundHandle(row){
      this.$confirm('此操作将申请订单退款, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          refund({orderId:row.orderId}).then(res=>{
            let data =res.data;
            if(data.code ==='000'){
              this.getList();
              this.$message.success(data.desc);
            }else{
              this.$message.warning(data.desc);
            }
          })
        })
      
    },
    recordHandle(row){
      this.recordVisible = true;
      if(row.comments){
        this.gridData = [{
          operatorName:row.operatorName,
          refundTime:row.refundTime,
          comments:row.comments
        }]
      }else{
        this.gridData = []
      }
    }
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>